/* eslint-disable */
import axios from 'axios';
import { createBrowserHistory } from 'history';

const BASE_DEV_URL = 'https://lms-api.staging.arthapp.in/';
const history = createBrowserHistory();

const axiosHttp = axios.create({
  baseURL: `${BASE_DEV_URL}`,
});

axiosHttp.interceptors.request.use(
  (config) => {
    
    // Modify the request config here (e.g., add headers, authentication tokens)
    // const accessToken = JSON.parse(localStorage.getItem('auth_token'));
    const accessToken = localStorage.getItem('auth_token');

    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers.app_slug = 'lms';
      }
    }
    return {
      ...config,
      headers: {
        ...(accessToken !== null && { Authorization: `${accessToken}` }),
        ...config.headers,
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosHttp.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      history.push('/');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default axiosHttp;
