import axiosHttp from './axios-service';

const loginService = () => ({
  authenticateUser: async (payload) => {
    try {
      const response = await axiosHttp.post(`/oauth/token`, payload, {
        headers: {
          'Content-Type': 'application/json',
          app_slug: 'lms',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user-info');
      // use toaster msg
      throw error;
    }
  },
});

export default loginService;
