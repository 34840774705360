import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../pages/login/Login";
// import SlidingPanel from "react-sliding-side-panel";

const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const Sidebar = lazy(() => import("../component/sidebar/Sidebar"));
const Template = lazy(() => import("../pages/template/Template"));
const CreateTemplate = lazy(() => import("../pages/template/CreateTemplate"));
const Segment = lazy(() => import("../pages/segments/Segment"));
const CreateSegment = lazy(() => import("../pages/segments/CreateSegment"));
const Workflow = lazy(() => import("../pages/workflows/Workflow"));
const CreateWorkFlow = lazy(() => import("../pages/workflows/CreateWorkFlow"));
const UploadSegment = lazy(() => import("../pages/segments/Upload-segment"));
const PreviewTemplate = lazy(() => import("../pages/template/PreviewTemplate"));
const PreviewWorkflow = lazy(
  () => import("../pages/workflows/PreviewWorkflow")
);
const PreviewSegment = lazy(() => import("../pages/segments/PreviewSegment"));
const CustomerProfile = lazy(() => import("../pages/ovc/CustomerProfile"));
const CustomerDetails = lazy(() => import("../pages/ovc/CustomerDetails"));
const LoandetailsCustomer  = lazy(() => import("../component/loan-details/CustomerLoanDetails"))
const Routers = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <Sidebar
              component={
                <Suspense>
                  <Dashboard />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/template"
          create
          element={
            <Sidebar
              component={
                <Suspense>
                  <Template />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/create-template"
          element={
            <Sidebar
              component={
                <Suspense>
                  <CreateTemplate />
                </Suspense>
              }
            />
          }
        />
         <Route
          path="/customer-loan-details"
          element={
            <Sidebar
              component={
                <Suspense>
                  <LoandetailsCustomer />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/preview-template"
          element={
            <Sidebar
              component={
                <Suspense>
                  <PreviewTemplate />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/segment"
          element={
            <Sidebar
              component={
                <Suspense>
                  <Segment />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/create-segment"
          element={
            <Sidebar
              component={
                <Suspense>
                  <CreateSegment />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/upload-segment"
          element={
            <Sidebar
              component={
                <Suspense>
                  <UploadSegment />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/workflow"
          element={
            <Sidebar
              component={
                <Suspense>
                  <Workflow />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/create-workflow"
          element={
            <Sidebar
              component={
                <Suspense>
                  <CreateWorkFlow />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/preview-workflow"
          element={
            <Sidebar
              component={
                <Suspense>
                  <PreviewWorkflow />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/preview-segment"
          element={
            <Sidebar
              component={
                <Suspense>
                  <PreviewSegment />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/customer-profile"
          element={
            <Sidebar
              component={
                <Suspense>
                  <CustomerProfile />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/customer"
          element={
            <Sidebar
              component={
                <Suspense>
                  <CustomerDetails />
                </Suspense>
              }
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default Routers;
